<template>
  <div>
    <div id="content">
      <el-row>
        <el-col :span="12"
          ><div class="dleft">
            <div class="anl">
              <el-input
                v-model="keyword"
                placeholder="QuestionID"
                clearable
                size="small"
                style="width: 184px; margin-right: 115px"
              ></el-input>
              <el-button type="info" plain size="small" @click="selectAll"
                >全选</el-button
              ><el-button type="info" plain size="small" @click="valueQid = []"
                >全不选</el-button
              ><el-button
                size="small"
                type="primary"
                @click="add"
                :loading="loading"
                >添加</el-button
              >
            </div>
            <div>
              <el-cascader-panel
              ref="cascaderPanel" 
                v-model="valueQid"
                :options="optionsFil"
                :props="{ multiple: true, emitPath: false }"
              ></el-cascader-panel>
            </div>
            <div class="weizhi" v-if="weiShow">
                  <p>multi 类型需要选取横向维度</p>
                  <el-radio-group v-model="wei">
                    <el-radio label="1"   >Answer Code</el-radio>
                    <el-radio label="2"   >Loop</el-radio>
                    <el-radio label="3" >QuestionID</el-radio>
                  </el-radio-group>
            </div>
            </div
        ></el-col>
        <el-col :span="12"
          ><div class="dright" v-show="configs.length > 0">
            <div class="anl">
              <span style="font-size: 13px; color: #afafaf"
                >已创建组合表格，共 {{ configs.length }} 个</span
              >
            </div>
            <div class="tags">
              <el-tag
                v-for="(item, index) in configs"
                :key="index"
                closable
                :type="'info'"
                @close="tagDel(index, item.id)"
                effect="light"
              >
                {{ item.qid }}
              </el-tag>
            </div>
          </div></el-col
        >
      </el-row>
    </div>
  </div>
</template>
  <script>
export default {
  name: "KpiZuhe",
  props: ["kid", "pid"],
  data() {
    return {
      loading: false,
      keyword: "",

      valueQid: [],
      configs: [],
      qidcode: {},
      wei:"1",
      weiShow:false,
      options: [],
      optionsFil: [],
    };
  },
  watch: {
    keyword: {
      handler(val) {
        val = val.toLowerCase();
        this.optionsFil = this.options.filter((p) => {
          return p.label.toLowerCase().indexOf(val) !== -1;
        });
      },
    },
    valueQid(val) {
      if(val.length==0){
        this.wei ="1";
        this.weiShow=false;
        return;
      }
      let qid = val[0];
      if (this.qidcode[qid].Tpe!="multi"){
        return
      }
     this.weiShow=true
     
    },
  },
  methods: {
    getQid() {
      var api = this.COMMON.httpUrl + "qidSelect";
      var data = {
        pid: this.pid,
        kid: this.kid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.configs = rs.data.cfg;
          let dt = rs.data.qids;
          let dd = [];
          for (let key of dt) {
            dd.push({ value: key.Qid, label: key.Qid+" "+key.Qlabel });
            this.qidcode[key.Qid] = key;
          }
          this.options = dd;
          this.optionsFil = dd;
        }
      });
    },
    selectAll() {
      this.valueQid = [];
      for (let v1 of this.optionsFil) {
        this.valueQid.push([v1.value]);
      }
    },
    add2() {
      alert(this.wei)
    },
    add() {
      if (this.valueQid.length == 0) {
        alert("请至少选取一个题目");
        return;
      }
     
      let cfg = {};
      cfg["qid"] = this.valueQid.join(",");
      cfg["tpe"] = this.qidcode[this.valueQid[0]].Tpe;
      cfg["wei"] = this.wei;
      cfg["pid"] = this.pid;
      cfg["kid"] = this.kid;
      this.save(cfg);
    },
    // 提交保存
    save(cfg) {
      this.loading = true;
      var api = this.COMMON.httpUrl + "kpiZuheSave";
      var data = {
        kid: this.kid,
        cfg: cfg,
      };
      this.axios.post(api, data).then((response) => {
        this.loading = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.configs = rs.data;
          // 重置
          // this.valueQid = [];
          this.$refs.cascaderPanel.clearCheckedNodes()
          this.keyword = "";
          this.wei ="1";
          this.weiShow=false;
        }
      });
    },

    tagDel(index, id) {
      var api = this.COMMON.httpUrl + "kpiZuheDel";
      var data = {
        kid: this.kid,
        id: id,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.configs.splice(index, 1);
          this.$message.success(rs.message);
        }
      });
    },
  },
  created() {
    localStorage.setItem("kpitag","six")
    this.getQid();
  },
};
</script>
  <style scoped>
#content {
  background-color: white;
  padding-top: 40px;
  padding-left: 50px;
}
.dleft {
  width: 550px;
}
.anl {
  margin-bottom: 20px;
}
/* v-deep深度选择器 */
::v-deep .el-cascader-menu__wrap {
  height: 350px;
}
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0;
}
.tags {
  border: 2px dashed #e0e5f0;
  width: 550px;
  height: 550px;
  margin-left: 0px;
  margin-top: 20px;
  padding: 10px;
  overflow: auto;
}

.tags span {
  margin-left: 10px;
  margin-top: 10px;
}
.weizhi{
margin-top:25px;
}
.weizhi p{
  font-size:14px;
  color:rgb(175, 175, 175);
  padding:15px 0;
}
</style>
 