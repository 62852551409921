<template>

    <div>
        <div id="goback" style="margin-bottom:10px;font-size:14px;">
            <i class="el-icon-back"  @click="$router.go(-1)" style="cursor:pointer;font-size:14px;">返回</i>&nbsp;&nbsp; <span style="font-size:14px;color:rgb(192, 196, 204)">|</span> &nbsp;&nbsp;<span style="font-size:16px;color:rgb(192, 196, 204)">编号：</span>{{ppcode}} <span style="font-size:14px;color:rgb(192, 196, 204)">项目：</span>{{ppname}}       
        </div>
        <div id="content">

            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" style="width:600px">
                <el-form-item label="项目编号" prop="pcode"  v-if="showForm.pcode">
                    <el-input v-model="ruleForm.pcode" placeholder="请输入项目编号" :disabled="disableForm.pcode"></el-input>
                </el-form-item>

                <el-form-item label="项目名称" prop="pname" v-if="showForm.pname">
                    <el-input v-model="ruleForm.pname" placeholder="请输入项目名称" :disabled="disableForm.pname"></el-input>
                </el-form-item>
                <el-form-item label="客户名称" prop="guestname" v-if="showForm.guestname">
                    <el-input v-model="ruleForm.guestname" placeholder="请输入客户名称" :disabled="disableForm.guestname">
                    </el-input>
                </el-form-item>
                <el-form-item label="样本量" prop="yangben" v-if="showForm.yangben">
                    <el-input-number v-model="ruleForm.yangben" :step="50" :min="100" :max="10000" label="句子基数" :disabled="disableForm.yangben"> 
                    </el-input-number>
                </el-form-item>
                <el-form-item label="执行天数" prop="zhixingtian" v-if="showForm.zhixingtian">
                    <el-input v-model="ruleForm.zhixingtian" placeholder="请输入执行天数" :disabled="disableForm.zhixingtian">
                    </el-input>
                </el-form-item>
                <el-form-item label="问卷时长" prop="wenjuanshichang" v-if="showForm.wenjuanshichang">
                    <el-input v-model="ruleForm.wenjuanshichang" placeholder="请输入正整数，单位：分钟" :disabled="disableForm.wenjuanshichang">
                    </el-input>
                </el-form-item>

                <el-form-item label="complete数量" prop="completecount" v-if="showForm.completecount">
                    <el-input v-model="ruleForm.completecount" placeholder="complete数量" :disabled="disableForm.completecount">
                    </el-input>
                </el-form-item>

                <el-form-item label="预估报价" prop="yugubaojia" v-if="showForm.yugubaojia">
                    <el-input v-model="ruleForm.yugubaojia" placeholder="请输入预估报价"  :disabled="disableForm.yugubaojia">
                    </el-input>
                </el-form-item>
                <el-form-item label="实际结算价" prop="shijijiesuan" v-if="showForm.shijijiesuan">
                    <el-input v-model="ruleForm.shijijiesuan" placeholder="实际结算价" :disabled="disableForm.shijijiesuan">
                    </el-input>
                </el-form-item>


                <el-form-item label="预估收款时间" v-if="showForm.yugushoukuan">
                    <el-date-picker v-model="ruleForm.yugushoukuan" type="date" format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="disableForm.yugushoukuan">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="实际收款时间"  v-if="showForm.shijishoukuan">
                    <el-date-picker v-model="ruleForm.shijishoukuan" type="date" format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd" placeholder="选择日期"  :disabled="disableForm.shijishoukuan">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="实际产生样本" prop="shijiyangben" v-if="showForm.shijiyangben">
                    <el-input v-model="ruleForm.shijiyangben" placeholder="实际产生样本" :disabled="disableForm.shijiyangben">
                    </el-input>
                </el-form-item>
                <el-form-item label="利润率" prop="lirunlv"  v-if="showForm.lirunlv">
                    <el-input v-model="ruleForm.lirunlv" placeholder="利润率" :disabled="disableForm.lirunlv">
                    </el-input>
                </el-form-item>
                <el-form-item label="响应率" prop="xiangyinglv" v-if="showForm.xiangyinglv">
                    <el-input v-model="ruleForm.xiangyinglv" placeholder="响应率"  :disabled="disableForm.lirunlv">
                    </el-input>
                </el-form-item>
                <el-form-item label="渗透率" prop="shentou" v-if="showForm.shentou">
                    <el-input v-model="ruleForm.shentou" placeholder="渗透率"  :disabled="disableForm.shentou">
                    </el-input>
                </el-form-item>
                <el-form-item label="QC量" prop="qc" v-if="showForm.qc">
                    <el-input v-model="ruleForm.qc" placeholder="请输入QC量,最大不能超过响应量" :disabled="disableForm.qc">
                    </el-input>
                </el-form-item>
                <el-form-item label="中断率" prop="zhongduan" v-if="showForm.zhongduan"> 
                    <el-input v-model="ruleForm.zhongduan" placeholder="中断率" :disabled="disableForm.zhongduan">
                    </el-input>
                </el-form-item>
                <el-form-item label="配额满量" prop="peieliang" v-if="showForm.peieliang">
                    <el-input v-model="ruleForm.peieliang" :placeholder="peieTip" :disabled="disableForm.peieliang">
                    </el-input>
                </el-form-item>
                <el-form-item label="平均完成时长" prop="pingjunshichang" v-if="showForm.pingjunshichang">
                    <el-input v-model="ruleForm.pingjunshichang" placeholder="请输入正整数，单位：秒" :disabled="disableForm.pingjunshichang">
                    </el-input>
                </el-form-item>
                <el-form-item label="PM名" prop="pmname" v-if="showForm.pmname">
                    <el-input v-model="ruleForm.pmname" placeholder="PM名" :disabled="disableForm.pmname">
                    </el-input>
                </el-form-item>

                <el-form-item label="客户cc单编号" prop="kehucc">
                    <el-input v-model="ruleForm.kehucc" placeholder="请输入客户cc单编号">
                    </el-input>
                </el-form-item>
                <el-form-item label="开票日期" prop="kaipiaoriqi">
                    <el-date-picker v-model="ruleForm.kaipiaoriqi" type="date" format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy-MM-dd" placeholder="请输入开票日期">
                    </el-date-picker>
                </el-form-item>              
                <el-form-item label="Hosting数量" prop="hosting">
                    <el-input v-model="ruleForm.hosting" placeholder="请输入Hosting数量">
                    </el-input>
                </el-form-item> 
                <el-form-item label="OE承接人-1" prop="oe1">
                    <el-input v-model="ruleForm.oe1" placeholder="请输入OE承接人-1">
                    </el-input>
                </el-form-item> 
                <el-form-item label="OE承接人-2" prop="oe2">
                    <el-input v-model="ruleForm.oe2" placeholder="请输入OE承接人-2">
                    </el-input>
                </el-form-item> 
                <el-form-item label="外包编程" prop="waibaobiancheng">
                    <el-input v-model="ruleForm.waibaobiancheng" placeholder="请输入外包编程">
                    </el-input>
                </el-form-item> 

                <el-form-item label="程序员名" prop="codername" v-if="showForm.codername">
                    <el-input v-model="ruleForm.codername" placeholder="程序员名" :disabled="disableForm.codername">
                    </el-input>
                </el-form-item>
                <el-form-item label="QC名" prop="qcname" v-if="showForm.qcname">
                    <el-input v-model="ruleForm.qcname" placeholder="QC名" :disabled="disableForm.qcname">
                    </el-input>
                </el-form-item>
                <el-form-item label="城市" prop="chengshi">
                    <el-input v-model="ruleForm.chengshi" placeholder="请输入城市">
                    </el-input>
                </el-form-item>
             
                <el-form-item label="城市题号" prop="cityname"  v-if="showForm.cityname">
                    <el-input v-model="ruleForm.cityname" placeholder="请输入datamap中城市所对应的题号" :disabled="disableForm.cityname">
                    </el-input>
                </el-form-item>

                <el-form-item label="其他信息">
                    <el-checkbox v-model="ruleForm.zhujuan" v-if="showForm.zhujuan" :disabled="disableForm.zhujuan">设为主卷</el-checkbox>
                    <el-checkbox v-model="ruleForm.track" v-if="showForm.track" :disabled="disableForm.track">设为track</el-checkbox>
                    <el-checkbox v-model="ruleForm.kaipiao" v-if="showForm.kaipiao" :disabled="disableForm.kaipiao">是否开票</el-checkbox>
                    <el-checkbox v-model="ruleForm.shoukuan" v-if="showForm.shoukuan" :disabled="disableForm.shoukuan">是否收款</el-checkbox>
                </el-form-item>
                <el-form-item label="人群精准定向">                    
                    <el-checkbox v-model="ruleForm.crowdtarget"></el-checkbox>
                </el-form-item>
                <el-form-item label="人群描述">
                    <el-input v-model="ruleForm.crowddesc" placeholder="请输入人群描述">
                    </el-input>
                </el-form-item>
                <el-form-item label="置顶显示" v-if="showForm.topup" :disabled="disableForm.topup">                    
                    <el-checkbox v-model="ruleForm.topup"></el-checkbox>
                </el-form-item>
                <el-form-item label="所属成员" prop="members" class="members" v-if="showForm.members">
                    <el-checkbox-group v-model="ruleForm.members" >
                        <el-checkbox v-for="mem in memberoptions" :label="mem.id" :key="mem.id">{{ mem.name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')" :loading="loading">保存修改</el-button>
                    <el-button @click="$router.back()">返回</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>
<script>
export default {

    name: 'ProjectEdit',
    props: ['pid','ppname','ppcode'],
    data() {
        return {
            loading: false,
            memberoptions: [],
            ruleForm: {
                id:'',
                pcode:'',
                pname: '',
                zhujuan: false,
                track: true,
                yangben: 10,
                zhixingtian: '',
                wenjuanshichang: '',
                yugubaojia: '',
                completecount: '',
                shijijiesuan: '',
                kaipiao: '',
                yugushoukuan: '',
                shijishoukuan: '',
                shoukuan: '',
                shijiyangben: '',
                lirunlv: '',
                sendimage: '',
                xiangyinglv: '',
                shentou: '',
                qc: '',
                zhongduan: '',
                peieliang: '',
                pingjunshichang: '',
                pmname: '',
                kehucc: '',
                kaipiaoriqi: '',
                hosting:'',
                oe1:'',
                oe2:'',
                waibaobiancheng: '',
                codername: '',
                qcname: '',
                guestname:'',
                chengshi:'',
                cityname:'',        
                crowdtarget:'',
                crowddesc:'',
                topup:'',
                members: [],

            },

            rules: {

                pname: [
                    { required: true, message: '请输入项目名称', trigger: 'change' }

                ],
                zhixingtian: [
                    { required: true, message: '请输入执行天数', trigger: 'change' }

                ],
                wenjuanshichang: [
                    { required: true, message: '请输入问卷时长', trigger: 'change' }

                ],
                yugubaojia: [
                    { required: true, message: '请输入预估报价', trigger: 'change' }

                ]


                // pname: [
                //     { required: true, message: '请输入项目名称', trigger: 'change' },
                //     { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'change' }
                // ]

            },
            disableForm: {
                pcode:true,
                pname: true,
                zhujuan: false,
                track: true,
                yangben: true,
                zhixingtian: true,
                wenjuanshichang: true,
                yugubaojia: true,
                completecount: true,
                shijijiesuan: true,
                kaipiao: true,
                yugushoukuan: true,
                shijishoukuan: true,
                shoukuan: true,
                shijiyangben: true,
                lirunlv: true,
                sendimage: true,
                xiangyinglv: true,
                shentou: true,
                qc: true,
                zhongduan: true,
                peieliang: true,
                pingjunshichang: true,
                pmname: true,
                codername: true,
                qcname: true,
                guestname:true,
                cityname:true,        
                crowdtarget:true,
                crowddesc:true,
                topup:false,

            },
            showForm: {
                pcode:true,
                pname: true,
                zhujuan: false,
                track: true,
                yangben: true,
                zhixingtian: true,
                wenjuanshichang: true,
                yugubaojia: true,
                completecount: true,
                shijijiesuan: true,
                kaipiao: true,
                yugushoukuan: true,
                shijishoukuan: true,
                shoukuan: true,
                shijiyangben: true,
                lirunlv: true,
                sendimage: true,
                xiangyinglv: true,
                shentou: true,
                qc: true,
                zhongduan: true,
                peieliang: true,
                pingjunshichang: true,
                pmname: true,
                codername: true,
                qcname: true,
                members:true,
                guestname:true,
                cityname:true,   
                crowdtarget:true,
                crowddesc:true,
                topup:false,

            }
        }
    },
    mounted() {
        this.ruleForm.id = this.pid
        this.getInfo()      
        this.gertMem() 
        const permission = localStorage.getItem('permission')
        var perm=JSON.parse(permission)
        this.showForm=perm.projectInfo.showForm
        this.disableForm=perm.projectInfo.disableForm
    },
    computed: {
        // 计算属性
        peieTip() {
          const m = this.ruleForm.yangben * 0.5;
          const n = this.ruleForm.yangben * 2;
          return `${m}-${n}`;
        }
      },
    methods: {
        gertMem(){
            let api = this.COMMON.httpUrl + "member"
            let data = { pid: this.pid }
            this.axios.post(api, data).then((response) => {
                let rs = response.data
                if (rs.code) {
                    this.$message.error(rs.message);
                } else {                   
                    this.memberoptions = rs.message
                }

            })
        },
        getInfo() {
            let api = this.COMMON.httpUrl + "projectInfo"
            let data = { pid: this.pid }
            this.axios.post(api, data).then((response) => {
                let rs = response.data

                if (rs.code) {
                    this.$message.error(rs.message);
                } else {
                    
                    this.ruleForm = rs.message
                    // let memb=JSON.parse(rs.message.members)
                    let memb=rs.message.members
                    if (memb.length>0){
                        this.ruleForm.members=memb.map(Number)
                    }else{
                        this.ruleForm.members=[]
                    }
                    
                    if (rs.message.zhujuan) {
                        this.ruleForm.zhujuan = true
                    } else {
                        this.ruleForm.zhujuan = false
                    }
                    if (rs.message.track) {
                        this.ruleForm.track = true
                    } else {
                        this.ruleForm.track = false
                    }

                    if (rs.message.kaipiao) {
                        this.ruleForm.kaipiao = true
                    } else {
                        this.ruleForm.kaipiao = false
                    }
                    if (rs.message.shoukuan) {
                        this.ruleForm.shoukuan = true
                    } else {
                        this.ruleForm.shoukuan = false
                    }
                    if (rs.message.topup) {
                        this.ruleForm.topup = true
                    } else {
                        this.ruleForm.topup = false
                    }
                    if (rs.message.crowdtarget) {
                        this.ruleForm.crowdtarget = true
                    } else {
                        this.ruleForm.crowdtarget = false
                    }
                                    

                }

            })
        },

        submitForm(formName) {

            console.log(this.ruleForm.members)
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.loading = true
                    let api = this.COMMON.httpUrl + "projectEdit"

                    this.axios.post(api, this.ruleForm).then((response) => {
                        let rs = response.data
                        this.loading = false
                        if (rs.code) {
                            this.$message.error(rs.message);
                        } else {
                            this.$message.success(rs.message);
                            //  console.log(rs.message)

                        }

                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });



        }

    }

}
</script>
<style scoped>
#content {
    background-color: white;
    width: 900px;
    text-align: left;
    padding-left: 0px;
    padding-top:50px;

}
.members{
    background-color: rgb(242, 246, 248);
    border-radius: 5px;
}
</style>