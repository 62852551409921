<template>
    <div>
        <div id="goback" style="margin-bottom:30px;font-size:14px;">
            <i class="el-icon-back"  @click="$router.go(-1)" style="cursor:pointer;font-size:14px;">返回</i>&nbsp;&nbsp; <span style="font-size:16px;color:rgb(192, 196, 204)">|</span> &nbsp;&nbsp;<span style="font-size:14px;color:rgb(192, 196, 204)">编号：</span>{{pcode}} <span style="font-size:14px;color:rgb(192, 196, 204)">项目：</span>{{pname}}      
        </div>
        <el-tabs v-model="activeName" type="card">
           
            <el-tab-pane label="Logic" name="one">
                <InfoLogic :pid="pid"></InfoLogic>
            </el-tab-pane>

            <el-tab-pane v-if="perm.pv" label="Pv" name="two" :lazy="true">
                <InfoPv :pid="pid"></InfoPv>
            </el-tab-pane>
            <el-tab-pane label="Scene" name="three" :lazy="true">
                <InfoScene :pid="pid"></InfoScene>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import InfoLogic from './InfoLogic'
import InfoPv from './InfoPv'
import InfoScene from './InfoScene'

export default {
    name: 'InfoIndex',
    props: ['pid','pname','pcode'],
    data() {
        return {
            activeName: 'one',
            perm:{
                pv:false
            }
        
        };
    },
    components: {
        InfoLogic,InfoPv,InfoScene
    },
    methods: {
    },
    created() {
        const permission = localStorage.getItem("permission");
        var perm = JSON.parse(permission);
        this.perm.pv = perm.page.infopv;
    },

}
</script>
