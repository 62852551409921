<template>
  <div>
    <div id="content">
      <div class="op">
        <el-button type="danger" size="small" icon="el-icon-delete" @click="del"
          >删除</el-button
        >
        <el-button type="danger" size="small" icon="el-icon-delete" @click="dels"
          >一键删除</el-button
        >
        <el-button size="small" @click="read">全部已读</el-button>
      </div>
      <div class="plist">
        <el-table
          :data="tableData"
          ref="multipleTable"
          size="mini"
          style="width: 100%"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column prop="id" label="id" v-if="false"> </el-table-column>
          <el-table-column prop="pname" label="项目名称" width="200">
            <template slot-scope="scope">
              <span :class="{ weidu: scope.row.read == 0 }">{{
                scope.row.pname
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="model" label="模块" width="150">
            <template slot-scope="scope">
              <span :class="{ weidu: scope.row.read == 0 }">{{
                scope.row.model
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="消息等级" width="100">
            <template slot-scope="scope" >
              <span v-if="scope.row.tp == 0" style="color: red" :class="{ weidu: scope.row.read == 0 }">严重</span>
              <span v-else-if="scope.row.tp == 1" style="color: #ffb731" :class="{ weidu: scope.row.read == 0 }">警告</span
              >
              <span v-else :class="{ weidu: scope.row.read == 0 }">一般</span>
            </template>
          </el-table-column>
          <el-table-column prop="created_time" label="创建日期" width="180">
          </el-table-column>
          <el-table-column prop="txt" label="消息内容">
            <template slot-scope="scope">
              <span :class="{ weidu: scope.row.read == 0 }">{{
                scope.row.txt
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="fenye">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="20"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageMessage",
  data() {
    return {
      loading: false,
      upurl: "",
      multipleSelection: [],
      tableData: [],
      currentPage: 1,
      total: 1,
      token: "",
    };
  },
  created() {
    this.handleCurrentChange(1);
  },
  methods: {
    read() {
      var api = this.COMMON.httpUrl + "messageState";
      var data = {};
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
      this.$router.go(0)
        }
      });
    },
    del() {
      var s = [];
      this.$refs.multipleTable.selection.forEach((Ele) => {
        s.push(Ele.id);
      });
      if (s.length == 0) {
        return;
      }

      this.$confirm("删除后不可恢复,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.rundel();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    rundel() {
      var s = [];
      this.$refs.multipleTable.selection.forEach((Ele) => {
        s.push(Ele.id);
      });

      var api = this.COMMON.httpUrl + "messageDel";
      var data = {
        tids: s,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.tableData = rs.data.data;
          this.currentPage = rs.data.current_page;
          this.total = rs.data.total;
        }
      });
    },
    dels() {     

      var api = this.COMMON.httpUrl + "messageDelete";
      var data = {        
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success("删除完成!");
          this.tableData = rs.data.data;
          this.currentPage = rs.data.current_page;
          this.total = rs.data.total;
        }
      });
    },
 
    handleCurrentChange(page) {
      var api = this.COMMON.httpUrl + "messageList";
      var data = {
        page: page,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.tableData = rs.data.data;
          this.currentPage = rs.data.current_page;
          this.total = rs.data.total;
        }
      });
    },

  },
};
</script>
  
<style scoped>
#content {
  background-color: white;
  width: 1200px;
  text-align: left;
  padding-left: 0px;
}

.op {
  padding: 20px 0;
}

.fenye {
  margin-top: 20px;
}

.cell a {
  color: #666;
  text-decoration: none;
}

.cell a:hover {
  color: rgb(125, 163, 234);
  text-decoration: underline;
}

.caozuo {
  color: #409eff !important;
  margin-right: 10px;
}

.result {
  color: #409eff !important;
  margin-left: 0px;
}
.weidu {
  font-weight: 800;
  color: #000;
}
</style>