<template>
  <div>
    <div id="content">
      <el-row>
        <el-col :span="12"
          ><div class="dleft">
            <div class="anl">
              <el-input v-model="keyword" placeholder="搜索"  clearable size="small" style="width:184px;margin-right:115px"></el-input>
              <el-button type="info" plain size="small" @click="selectAll"
                >全选</el-button
              ><el-button type="info" plain size="small" @click="valueQid = []"
                >全不选</el-button
              ><el-button size="small" type="primary" @click="add">添加</el-button
              >
            </div>
            <div>
              <el-cascader-panel
              ref="cascaderPanel" 
                v-model="valueQid"
                :options="optionsFil"
                :props="{multiple: true, checkStrictly: false }"
              ></el-cascader-panel>
            </div>
            <div style="margin-top: 60px">
              <el-select
                v-model="hongj"
                multiple                
                clearable
                collapse-tags
                placeholder="请选择宏"
              >
                <el-option
                  v-for="(item,index) in hongs"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div></div
        ></el-col>
        <el-col :span="12"
          ><div class="dright">
            <div class="anl">
              <el-button type="info" plain size="small" @click="clear"
                >清空</el-button
              >
              <el-button type="success" size="small" @click="save" :loading="loading"
                >保存</el-button
              >&nbsp;&nbsp;&nbsp;&nbsp;<span
                style="font-size: 13px; color: #afafaf"
                >已选中表头，共 {{ configs.length }} 条</span
              >
            </div>
            <div style="height: 500px; overflow: auto">
              <table border="0">
                <tr
                  style="height: 40px"
                  v-for="(conf, index) in configs"
                  :key="index"
                >
                <td
                    style="min-with: 40px; padding-right: 5px; font-size: 13px"
                    v-if="conf.bian==1"
                  >
                    {{ conf.qid }}
                  </td>
                  <td
                  v-else
                    style="min-with: 40px; padding-right: 5px; font-size: 13px"
                  >
                    宏
                  </td>
                  <td>
                    <el-input
                      v-model="conf.alabel"
                      size="mini"
                      style="width: 260px"
                    ></el-input>
                  </td>
                  <td width="90" align="center">
                    <el-checkbox
                      v-model="conf.reverse"
                      :true-label="1"
                      :false-label="0"
                      >不等于</el-checkbox
                    >
                  </td>
                  <td>
                    <i
                      class="el-icon-remove-outline"
                      style="font-size: 16px"
                      @click="del(index)"
                    ></i>
                  </td>
                </tr>
              </table>
            </div></div
        ></el-col>
      </el-row>
    </div>
  </div>
</template>
  <script>
// configs配置对象数组
// config{pid:"",kid:"",weidu:"",bian:1,reverse:true,vid:"",qid:"",alabel:"",acode:"",tp:"",lp:"",exp:""}
// weidu:1 表头，2表侧
// bian:1 变量 ，2宏
// reverse：不选，反向

export default {
  name: "KpiHead",
  props: ["kid", "pid"],
  data() {
    return {
      loading:false,
      keyword:"",      
      valueQid: [],      
      configs: [],
      qidcode: [],
      dels: [],
      hongj: [],
      hongm: {},
      hongs: [],
      options: [],
      optionsFil: [],
    };
  },
  watch:{
    keyword:{      
      handler(val){
        val=val.toLowerCase()
        this.optionsFil=this.options.filter((p)=>{
          return p.label.toLowerCase().indexOf(val) !== -1
        })
      }
    }
  },
  methods: {
    getQid() {
      this.$message.warning("数据加载中，请稍候");
      var api = this.COMMON.httpUrl + "kpiSelect";
      var data = {
        weidu: "1",
        pid: this.pid,
        kid: this.kid,
      };
      this.axios.post(api, data).then((response) => {
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.options = rs.data.bs;
          this.optionsFil = rs.data.bs;
          this.qidcode = rs.data.ds;
          this.configs = rs.data.cf;
          for (let h of rs.data.hong){
            this.hongs.push({value:h.key,label:h.key})
            this.hongm[h.key]=h.values
          }

        }
      });
    },
    selectAll() {
      this.valueQid = [];
      for (let v1 of this.optionsFil) {
        for (let v2 of v1.children) {
          this.valueQid.push([v1.value, v2.value]);
        }
      }
    },
    add() {
      // console.log(this.valueQid)
      for (let v of this.valueQid) {
        let vm = this.qidcode[v[0] + "#" + v[1]];      
        if (this.ckchong1(vm.qid,vm.vid,vm.acode)) {
          continue;
        }   
        this.configs.push({
          pid: this.pid,
          kid: this.kid,
          weidu: 1,
          bian: 1,
          reverse: false,
          vid: vm.vid,
          qid: vm.qid,
          alabel: vm.alabel,
          acode: vm.acode,
          tp: vm.tp,
          lp: vm.lp,
          exp: "",
        });
      }
      for (let h of this.hongj) {
        // console.log(v)
        if (this.ckchong2(h)) {
          continue;
        }
        this.configs.push({
          pid: this.pid,
          kid: this.kid,
          weidu: 1,
          bian: 2,//宏
          reverse: false,
          vid: "",
          qid: "",
          alabel: h,
          acode: "",
          tp: "hong",
          lp: "",
          exp: this.hongm[h],
        });
      }
      this.$refs.cascaderPanel.clearCheckedNodes()
      // this.valueQid = [];
      this.hongj = [];
    },
    clear() {
      for (let c of this.configs) {
        if ("id" in c) {
          this.dels.push(c.id);
        }
      }
      this.configs = [];
    },
    save() {
      // 处理confings中的反向，并填充exp
      this.loading=true
     for (let i = 0; i < this.configs.length; i++) {  
      let vm=this.configs[i]
       let exp = "";
        switch (vm.tp) {
          case "multi":
            if (vm.reverse) {
              // 反向
              exp = vm.vid + "!=1";
            } else {
              exp = vm.vid + "==1";
            }
            break;
          case "hong":
            break;
            default:
          // case vm.tp=="single" || vm.tp=="grid":
          if (vm.reverse) {
              // 反向
              exp = vm.vid + "!="+vm.acode;
            } else {
              exp = vm.vid + "=="+vm.acode;
            }            
        }
        if(exp.length>0){
          this.configs[i].exp=exp
        }      
      }

      // 提交
      var api = this.COMMON.httpUrl + "kpiChartAdd";
      var data = {
        kid: this.kid,
        pid: this.pid,
        weidu: "1",
        dels: this.dels,
        configs: this.configs,
      };
      this.axios.post(api, data).then((response) => {
        this.loading = false;
        var rs = response.data;
        if (rs.code) {
          this.$message.error(rs.message);
        } else {
          this.$message.success(rs.message);
          this.configs = rs.data;
        }
      });
    },
    del(index) {
      if ("id" in this.configs[index]) {
        this.dels.push(this.configs[index].id);
      }
      this.configs.splice(index, 1);
    },
    ckchong1(qid,vid, acode) {
      let k = qid + "#"+vid+ "#" + acode;
      for (let c of this.configs) {
        let p = c.qid + "#" + c.vid + "#" + c.acode;
        if (p == k) {
          // 重复
          return true;
        }
      }
      return false;
    },
    ckchong2(h) {
      for (let c of this.configs) {
        if (h == c.alabel) {
          // 重复
          return true;
        }
      }
      return false;
    },
  },
  created() {    
    localStorage.setItem("kpitag","three")
    this.getQid();
  },
};
</script>
  <style scoped>
#content {
  background-color: white;
  padding-top: 40px;
  padding-left: 50px;
}
.dleft {
  width: 500px;
}
.anl {
  margin-bottom: 20px;
}
::v-deep .el-cascader-menu__wrap {
  height: 350px;
}
</style>
 
   